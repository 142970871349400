@import url(https://fonts.googleapis.com/earlyaccess/notosanskr.css);


.list a::before{
    content: "";
    background-image: url(../img/icon__arrow_right_mini.png);
    /* background-image: url(${PlayMini}); */
    width: 24px;
    height: 24px;
    display: inline-block;
    position: absolute;
    left: -24px;
    top: -2px;
    background-size: cover;

}


.tt{

    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,0.5);
    width: 100%;
    height: 100%;
    padding: 36px 0 0 20px;
    box-sizing: border-box;
}

.circle{
    border-radius: 50%;
    width: 180px;
    height: 180px;
    position: absolute;
    top: 235px;
    left: 10px;
    overflow: hidden;
}
.circle-txt{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 5;
    color: #fff;
    font-size: 20px;
    font-weight: 700;
}













.img{    
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
}



.d{
    width: 100%;
    height: 300px;
    padding: 24px 0 0;
    position: relative;
}

.tit{
    font-weight: 700;
    font-size: 16px;
    padding: 12px 0 7px;
    text-align: center;
    height: 42px;
    width: 100%;
    color: #fff;
    border-bottom: 1px solid rgba(255,255,255,.2);
}

.tit a{
    position: absolute;    
    top: 30px;
    left: 20px;
    content: "";
    background-image: url(../img/topbar_back_white.png);
    /* background-image: url(${PlayMini}); */
    width: 30px;
    height: 30px;
    display: inline-block;
    font-size: 0;
}





.bo{
    margin: 0 20px;;
}

.p{

    margin: 28px 0 20px;
    font-size: 20px;
    font-weight: 700;
}
.pp{
    margin: 20px 0 40px;
    font-size: 13px;
    color:#757575;
}
.cb{

    margin: 40px auto 0;
    overflow: auto;
    height: 445px;
    box-sizing: border-box;
    padding-bottom: 20px;

}

.ul{

}
.li{
    padding: 16px 0 16px 2px;
    box-sizing: border-box;
    border-bottom: 1px solid #d9d9d9;
}

.s{
    font-size: 14px;
    display: inline-block;
    width: 278px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;    
    margin-left: 10px;

    width: 50%;
    padding: 0 12px;
    white-space: normal;
    display: -webkit-box;
}

/* .li input[type="checkbox"] {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 2px;
    cursor: pointer;
    background: url(${CheckButton});
    -webkit-appearance: none;
    -moz-appearance: none;
}
.li  input:checked {
    background: url(${CheckButtonActive});
} */


.pay{
    width: 500px;
    box-sizing: border-box;
    border: 1px solid #f5f5f5;
}
.pay th,td{
    padding: 8px;
    width: 250px;
    line-height: 1;
}
.pay th{
    text-align: center;
    color:#757575;
    background-color: #f5f5f5;
    height: 32px;
}
.pay td{
    text-align: left;
    height: 44px;
}
.pay td+td{
    border-left: 1px solid #f5f5f5;
}







.lele{
    width: 380px;
    height: 658px;
    float: left;

}
.lele+.lele{
    margin-left: 20px;
}
.lele p{
font-weight: 500;
font-size: 18px;
text-align: center;
padding: 16px 0;
border-bottom: 2px solid #000065;
}
.lele ul{
    display: block;
    border-top: 0;
    border: 1px solid #dbdbdb;
}
.lele li{
    padding: 18px 0 18px 20px;
    position: relative;

}

.lele li:hover{
    background-color: #f5f5f5;
    cursor: pointer;
}
.lele li.on{
    background-color: #f5f5f5;
}
.lele li.on .ti{
    margin-left: 24px;
    
}
.lele li.on .up,.lele li.on .down{
    display: inline-block;

}
.lele li.on::after{
    content: "";
    width: 24px;
    height: 24px;
    position: absolute;
    right: 4px;
    top: 18px;
    background-image: url(../img/icon__keyboard_arrow_right.png);

}


.up{
    display: none;
    content: "";
    width: 24px;
    height: 24px;
    position: absolute;    
    top: 4px;
    left: 8px;
    background-image: url(../img/icon__arrow_drop_up_default.png);
    font-size: 0;
}
.up:hover{
    background-image: url(../img/icon__arrow_drop_up_active.png);
}

.down{
    display: none;
    content: "";
    width: 24px;
    height: 24px;
    position: absolute;    
    bottom: 4px;
    left: 8px;
    background-image: url(../img/icon__arrow_drop_down_default.png);
    font-size: 0;
}
.down:hover{
    background-image: url(../img/icon__arrow_drop_down_active.png);
}
.up.on{
    background-image: url(../img/icon__arrow_drop_up_active.png);
}

.down.on{    
    background-image: url(../img/icon__arrow_drop_down_active.png);
}

